<template>
  <div>专科</div>
</template>

<script>
export default {
  name: '',
  props: ['userInfo'],
  data() {
    return {
    };
  },
  computed: {
  },
  created() {
  },
  methods: {},
};
</script>

<style scoped lang='less'></style>
